import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="search"
export default class extends Controller {
  connect() {
  }

  changeKind(event) {

  }

  search(event) {
    event.preventDefault();

    let city = event.target.parentElement.getElementsByClassName("filter-city")[0].value
    let district = event.target.parentElement.getElementsByClassName("filter-district")[0].value
    let quarter = event.target.parentElement.getElementsByClassName("filter-quarter")[0].value
    let kind = event.target.parentElement.getElementsByClassName("filter-kind")[0].value
    let query = event.target.parentElement.getElementsByClassName("filter-query")[0].value

    let fullPath = [city, district, quarter].filter((e) => e !== "").join("-")

    let result = ""

    if(kind === "")
      result = `/${fullPath}-okullari`
    else
      result = `/${kind}/${fullPath}`

    if (query !== "")
      result += `?q=${query}`

    document.location.href = result
  }

  changeCity(event) {
    console.log("changeCity")
    get(`/districts?city=${event.target.value}`, {
      responseKind: "turbo-stream"
    })
  }

  changeDistrict(event) {
    console.log("changeDistrict")
    let city = document.getElementById("city").value
    let district = document.getElementById("district").value

    get(`/quarters?city=${city}&district=${district}`, {
      responseKind: "turbo-stream"
    })
  }
}
