import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
// Connects to data-controller="city-select"
export default class extends Controller {
  changeCity(event) {
    get(`/districts?city=${event.target.value}`, {
      responseKind: "turbo-stream"
    })
  }

  search(event) {
    event.preventDefault();

    let city = document.getElementById("city").value
    let district = document.getElementById("district").value
    let kind = document.getElementById("kind").value

    if(district == "")
      document.location.href = `/${kind}/${city}`
    else
      document.location.href = `/${kind}/${city}-${district}`
  }
}
